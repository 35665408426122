<template>
  <div>
    <el-main>
      <el-form class="el-form-search" label-width="115px">
        <el-form-item label="评论关键字：">
          <el-input size="small" placeholder="请输入评价内容关键字" v-model="content"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称：">
          <el-input size="small" placeholder="请输入用户昵称" v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="评价日期：">
          <el-date-picker v-model="timeVal" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="接单人：">
          <el-input size="small" placeholder="" v-model="artisan_name"></el-input>
        </el-form-item>
        <el-form-item label="订单编号：">
          <el-input size="small" placeholder="请输入订单编号" v-model="pay_sn"></el-input>
        </el-form-item>
        <el-form-item label="评分等级：">
          <el-select v-model="score" size="small">
            <el-option v-for="item in levelSelect" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否回复：">
          <el-select v-model="is_reply" size="small">
            <el-option value="">全部</el-option>
            <el-option v-for="item in replyArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="getList()">搜索</el-button>
          <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <div class="order-list">
        <table class="order-table">
          <tbody v-if="!list.length">
            <tr style="background: #fff">
              <td colspan="3">
                <No />
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, index) in list" :key="index">
            <tr>
              <td colspan="1">评价人：{{ item.user_name }}</td>
              <td colspan="1">订单编号：{{ item.order_sn }}</td>
              <td colspan="1">下单人：{{ item.user_name }}</td>
              <td colspan="1">接单人：{{ item.artisan_name }}</td>
              <!--<td colspan="1">沈阳市铁西区：</td>-->
              <td colspan="1">
                评分：
                <el-rate v-model="item.score" disabled></el-rate>
              </td>
            </tr>
            <tr>
              <td>
                <div class="service-info">
                  <div style="width:15px;"></div>
                  <el-image :src="item.service_cover_picture"></el-image>
                  <div class="service-title">
    
                  </div>
                </div>
              </td>
              <td> <p>{{ item.service_name }}</p></td>
              <td>
                <div class="evaluate-info">
                  <div style="padding-top:15px;">
                    <span>评价用户：</span>
                    {{ item.user_name }}
                  </div>
                  <div style="display: flex">
                    <span style="flex-shrink: 0">评价内容：</span>
                    <p style="line-height: 16px">{{ item.content }}</p>
                  </div>
                  <div class="image" v-if="item.images_d">
                    <span>评价图片：</span>
                    <el-image :src="pictures" v-for="(pictures, indexs) in item.images_d" :preview-src-list="item.images_d" :key="indexs"></el-image>
                  </div>
                  <div>
                    <span>评价时间：</span>
                    {{ getDateformat(item.create_time) }}
                  </div>
                </div>
              </td>
              <td>
                <div class="complete-info">
                  <div>
                    <span>平台回复：</span>
                    {{ item.reply }}
                  </div>
                  <div class="complete-img" v-if="item.reply_images_d">
                    <span>图片：</span>
                    <el-image :src="pictures" :preview-src-list="item.reply_images_d" v-for="(pictures, indexs) in item.reply_images_d" :key="indexs"></el-image>
                  </div>
                </div>
              </td>
              <td>
                <el-button v-if="item.is_reply==0" type="primary" size="mini" @click="handleReply(item)">回复</el-button>
              </td>
            </tr>
<!-- 小卡片 -->
<el-dialog title="回复" :visible.sync="reply" width="50%">
<ul class="cardUl">
  <li>
    <div class="cardLi">
      <div class="content cardLiTab">
        <el-form ref="form" :model="form" label-width="130px" label-position="left">
        <el-form-item label="服务名称：">
            {{item.service_name}}
        </el-form-item>
        <el-form-item label="回复内容：">
          <el-input type="textarea" v-model="form.reply"></el-input>
        </el-form-item>
      <el-form-item label="服务图片：" class="item" >
        <ReadyUploadSource @getSource="getSource" @changeOrder="list => (form.reply_images = list)"
        @removeThis="index => form.reply_images.splice(index, 1)" :isMany="true"
        :manyPath="form.reply_images" :isManyMax="10" :maxSize="1024 * 1024"></ReadyUploadSource>
         <p class="tips">建议图片尺寸800*800px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
     </el-form-item>
      <el-form-item class="formBtn">
        <el-button @click="reply = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
    </el-form-item>
  </el-form>
</div>
</div>
</li>
</ul>
</el-dialog>

          </tbody>
        </table>
      </div>
      <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </div>
</template>
  <script>
import { getDateformat } from '../../util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      rows: 10,
      page: 1,
      total_number: 0,
      content: '',
      start_time: '',
      end_time: '',
      pay_sn: '',
      artisan_id:'',
      artisan_name:'',
      name:'',
      is_reply:'',
      score: '',
      list: [],
      timeVal: '',
      currentInfo:{},
      reply:false,
      levelSelect: [
        {
          val: '',
          label: '全部',
        },
        {
          val: 1,
          label: '一级',
        },
        {
          val: 2,
          label: '二级',
        },
        {
          val: 3,
          label: '三级',
        },
        {
          val: 4,
          label: '四级',
        },
        {
          val: 5,
          label: '五级',
        },
      ],
      replyArr:[
        {
          val: 1,
          label: '是',
        },
        {
          val: 0,
          label: '否',
        },
      ],
      form: {
        reply: '',
        reply_images: [],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    handleReply(item){
        this.currentInfo = item;
      this.reply = true;
    },
        // 获取组件中选中的图片
    getSource (list) {
        list.map(item => {
            this.form.reply_images.push(item.path);
        });
    },
    onSubmit(){
        this.form.id= this.currentInfo.id
        let data = this.form
        this.$axios.post(this.$api.service.orderCommentReply, data).then(res => {
            if (res.code == 0) {
                this.reply = false;
                this.getList();
            }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.content = '';
      this.pay_sn = '';
      this.score = 0;
      this.timeVal = '';
      this.start_time = '';
      this.end_time = '';
      this.getList();
    },
    getTime(val) {
      if (val) {
        this.start_time = val[0].getTime() / 1000;
        this.end_time = val[1].getTime() / 1000;
      } else {
        this.start_time = '';
        this.end_time = '';
      }
    },
    getList() {
      let data = {
        content: this.content,
        order_sn: this.pay_sn,
        score: this.score,
        page: this.page,
        rows: this.rows,
        start_time: this.start_time,
        end_time: this.end_time,
        name:this.name,
        is_reply:this.is_reply,
        artisan_id:this.artisan_id,
        artisan_name:this.artisan_name,
      };
      this.$axios.post(this.$api.service.orderComment, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.total_number = res.result.total;
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

  <style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 350px;
      }
      td:last-child {
        width: 350px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;
      background: #f8f9fa;
      td {
        padding: 15px 20px;
      }
    }
    tbody tr:last-child {
      td {
        padding: 15px 20px;
      }
      td:first-child {
        width: 350px;
      }
      td:last-child {
        width: 350px;
      }
    }
  }
  .service-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 70px;
      height: 70px;
      border-radius: 5px;
      flex-shrink: 0;
    }
    .service-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      text-align: left;
      height: 70px;
      p:nth-child(1) {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      p:nth-child(2) {
        display: flex;
        align-items: center;
      }
    }
  }
  .evaluate-info,
  .complete-info {
    span {
      font-weight: bold;
    }
    & > div {
      margin-bottom: 10px;
    }
    .image,
    .complete-img {
      display: flex;
      flex-wrap: wrap;
      .el-image {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
.cardUl {
  width: 100%;
  background-color: #fff;

  justify-content: space-between;
  flex-wrap: wrap;

  li {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: gray;
    }
  }

  .cardLi {
    width: 100%;
    position: relative;

    .cardLiTil {
      background-color: #f0eeee;
      color: gray;
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cardLiTab {
      padding: 20px;
    }

    .cardTabPage {
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 20px;
    }
  }
}

.el-form-item {
  margin-bottom: 10px;
}

.rejection {
  width: 100%;
  background-color: #fff;
  border: 2px solid #000;

  .rejectionTil {
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;

    span {
      background-color: #f0eeee;
    }
  }
  .rejectionTab {
    padding: 20px;
  }

  .rejectionPage {
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
</style>
